import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/projectsTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The client asked to design and code a website for their building company, the client already had bought the domain and had the logo printed on business cards.`}</p>
    <p>{`My initial idea for the client was to use something very minimalist. But after talking with the client it was clear that this wasn't exactly what the client imagined the site to be. So we kept on talking and revising the design until the client was happy with the result.`}</p>
    <p>{`The client wanted to have a contact form that could send an email to their email address when someone tried to contact the company - this could also keep the volume of the calls down. I decided to use a serverless function to use the mailgun api to send the form data through email.`}</p>
    <p>{`The client also wanted an easy way to add new pictures and work done to the site and the decision was made to use contentful to source all the media onto the website.`}</p>
    <h2 {...{
      "id": "info",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#info",
        "aria-label": "info permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Info`}</h2>
    <p><strong parentName="p">{`Website:`}</strong>{` `}<a parentName="p" {...{
        "href": "https://www.landl-build.com/"
      }}>{`https://www.landl-build.com/`}</a></p>
    <p><strong parentName="p">{`GitHub repo:`}</strong>{` Private`}</p>
    <h2 {...{
      "id": "images",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#images",
        "aria-label": "images permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Images`}</h2>
    <p>{`First proposal
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5cc9d9a161d85b34df4aa3d50ce273ef/03ffe/landl-proposal.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAIBAwQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAgH/2gAMAwEAAhADEAAAAejXtUJyAX//xAAZEAADAQEBAAAAAAAAAAAAAAAAAQIDBBP/2gAIAQEAAQUC208iOlXQxDP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAVEQEBAAAAAAAAAAAAAAAAAAAAAf/aAAgBAgEBPwFa/8QAGBAAAgMAAAAAAAAAAAAAAAAAABEgMYH/2gAIAQEABj8CwVR//8QAGxABAAMBAAMAAAAAAAAAAAAAAQARQSExcZH/2gAIAQEAAT8h40LG1ayvMaRz1AJ0GAvwfIuz/9oADAMBAAIAAwAAABAz/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAABACH/2gAIAQMBAT8QYMv/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QGDb/xAAbEAEBAAMBAQEAAAAAAAAAAAABEQAhMXFhgf/aAAgBAQABPxC/lA7SnL7j4lnG+/ZZ+5KKGw4DUA0oOHgMegN40eZ//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "First proposal",
            "title": "First proposal",
            "src": "/static/5cc9d9a161d85b34df4aa3d50ce273ef/953fe/landl-proposal.jpg",
            "srcSet": ["/static/5cc9d9a161d85b34df4aa3d50ce273ef/20b44/landl-proposal.jpg 125w", "/static/5cc9d9a161d85b34df4aa3d50ce273ef/bce2d/landl-proposal.jpg 250w", "/static/5cc9d9a161d85b34df4aa3d50ce273ef/953fe/landl-proposal.jpg 500w", "/static/5cc9d9a161d85b34df4aa3d50ce273ef/d0f75/landl-proposal.jpg 750w", "/static/5cc9d9a161d85b34df4aa3d50ce273ef/0a251/landl-proposal.jpg 1000w", "/static/5cc9d9a161d85b34df4aa3d50ce273ef/03ffe/landl-proposal.jpg 1200w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`LandL Build Home Page
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d911e9147459155c50117dc4b01a8c0e/03ffe/landl-index.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "201.6%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAoABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAMEBgEF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGu0nIzbTCvAhs9VXLmJjWYAf/EAB0QAAIDAAIDAAAAAAAAAAAAAAEDAAIREBIUIUH/2gAIAQEAAQUCPYzJhn3hjTRgffK+6tcq9KFYPkpmERR6nDP/xAAWEQEBAQAAAAAAAAAAAAAAAAAhACD/2gAIAQMBAT8BIz//xAAWEQEBAQAAAAAAAAAAAAAAAAAhACD/2gAIAQIBAT8BZz//xAAfEAACAgEEAwAAAAAAAAAAAAAAARExAgMSICMyM4H/2gAIAQEABj8CUyUyuCxSmTxEyFrJGPcnB7EUPcsvhR//xAAeEAEAAgICAwEAAAAAAAAAAAABABEhMXGhEGGBkf/aAAgBAQABPyErQw1iWhvy/J04mab+YhqVSQ3UVZ6sVgUpcZOb3FvhR8DpXGE5wke5P//aAAwDAQACAAMAAAAQdBJwjC//xAAXEQADAQAAAAAAAAAAAAAAAAAAEBEB/9oACAEDAQE/EBBiq//EABgRAAMBAQAAAAAAAAAAAAAAAAABEYEQ/9oACAECAQE/EMmR1kJz/8QAIBABAAMAAQQDAQAAAAAAAAAAAQARITFBUXHRgZHB8f/aAAgBAQABPxCoOa7B2nC1W0WQZTy9825Rj7Q0AW3wdJoWF+IYm0bGtrpkclGzLM31FqAkVxZFa4KLcHicCZs0c+5/fieym6dJXwCZG/kjb+M//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "LandL Build Home Page",
            "title": "LandL Build Home Page",
            "src": "/static/d911e9147459155c50117dc4b01a8c0e/953fe/landl-index.jpg",
            "srcSet": ["/static/d911e9147459155c50117dc4b01a8c0e/20b44/landl-index.jpg 125w", "/static/d911e9147459155c50117dc4b01a8c0e/bce2d/landl-index.jpg 250w", "/static/d911e9147459155c50117dc4b01a8c0e/953fe/landl-index.jpg 500w", "/static/d911e9147459155c50117dc4b01a8c0e/d0f75/landl-index.jpg 750w", "/static/d911e9147459155c50117dc4b01a8c0e/0a251/landl-index.jpg 1000w", "/static/d911e9147459155c50117dc4b01a8c0e/03ffe/landl-index.jpg 1200w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`LandL Build About Page
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/327570c456b5c1c57e91679f60f16c9f/03ffe/landl-about.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "164%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAhABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAEDBgIF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAH1eZrLF46ZOU1bGq9Cs1mQf//EABsQAAIDAQEBAAAAAAAAAAAAAAECAAMRFBAy/9oACAEBAAEFAjRUZzUzmpjtYGL3auldE0TRFzCq5LPrz//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BX//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAECAQE/AWP/xAAeEAACAgICAwAAAAAAAAAAAAAAARExAhAhMzJRgf/aAAgBAQAGPwLnBHWjwRGOEr2dYpUMtFlnJa183//EAB0QAQACAgIDAAAAAAAAAAAAAAEAERAhQWFRkbH/2gAIAQEAAT8hrbVaMi63TNWgkBQPPMaVwbMV1Z1PcN5ruFaM8FSup8Ecf//aAAwDAQACAAMAAAAQsCHw9B//xAAXEQADAQAAAAAAAAAAAAAAAAAAEBEB/9oACAEDAQE/EI6av//EABcRAAMBAAAAAAAAAAAAAAAAAAEQESH/2gAIAQIBAT8QqmxAv//EACIQAQABBAAGAwAAAAAAAAAAAAEAESExUUFhcaHR8YHB8P/aAAgBAQABPxDEj3FsQ3PRfM3d7zAaXqUFdMLtlUWXA2SnOR0Ma1i+SezIU/rR6o0Uqd+cy42GfWVekwfi0yjP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "LandL Build About Page",
            "title": "LandL Build About Page",
            "src": "/static/327570c456b5c1c57e91679f60f16c9f/953fe/landl-about.jpg",
            "srcSet": ["/static/327570c456b5c1c57e91679f60f16c9f/20b44/landl-about.jpg 125w", "/static/327570c456b5c1c57e91679f60f16c9f/bce2d/landl-about.jpg 250w", "/static/327570c456b5c1c57e91679f60f16c9f/953fe/landl-about.jpg 500w", "/static/327570c456b5c1c57e91679f60f16c9f/d0f75/landl-about.jpg 750w", "/static/327570c456b5c1c57e91679f60f16c9f/0a251/landl-about.jpg 1000w", "/static/327570c456b5c1c57e91679f60f16c9f/03ffe/landl-about.jpg 1200w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`LandL Build Portfolio Page
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bbb37c1b94b2532ce2fa99e867b7bca3/03ffe/landl-portfolio.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.2%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAARABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIDBAEG/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHdDZQTbB52o1nos//EABsQAAMAAgMAAAAAAAAAAAAAAAECAwAEEBEy/9oACAEBAAEFAqiKuyQUDXiRZSyrOneU9cf/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAVEQEBAAAAAAAAAAAAAAAAAAABIP/aAAgBAgEBPwFj/8QAIBAAAQMCBwAAAAAAAAAAAAAAAQACEAMSERMhIjNxgf/aAAgBAQAGPwJrXUwVxBA5YWzC5NuPeseT/8QAHBAAAgICAwAAAAAAAAAAAAAAAAERMSFREGFx/9oACAEBAAE/IZSgsOaJA7ILuKkors9GAjS7JMFHjn//2gAMAwEAAgADAAAAEAMHgf/EABcRAAMBAAAAAAAAAAAAAAAAAAEQEUH/2gAIAQMBAT8QE1Ff/8QAFxEAAwEAAAAAAAAAAAAAAAAAARARIf/aAAgBAgEBPxCsiC//xAAdEAEBAAICAwEAAAAAAAAAAAABEQAhQWExUaHw/9oACAEBAAE/EHkgDRFkmHmx5JNO/mCUggb5zctIE6VuM1hmncdjrfGCABAM/Q9Y+cc//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "LandL Build Portfolio Page",
            "title": "LandL Build Portfolio Page",
            "src": "/static/bbb37c1b94b2532ce2fa99e867b7bca3/953fe/landl-portfolio.jpg",
            "srcSet": ["/static/bbb37c1b94b2532ce2fa99e867b7bca3/20b44/landl-portfolio.jpg 125w", "/static/bbb37c1b94b2532ce2fa99e867b7bca3/bce2d/landl-portfolio.jpg 250w", "/static/bbb37c1b94b2532ce2fa99e867b7bca3/953fe/landl-portfolio.jpg 500w", "/static/bbb37c1b94b2532ce2fa99e867b7bca3/d0f75/landl-portfolio.jpg 750w", "/static/bbb37c1b94b2532ce2fa99e867b7bca3/0a251/landl-portfolio.jpg 1000w", "/static/bbb37c1b94b2532ce2fa99e867b7bca3/03ffe/landl-portfolio.jpg 1200w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`LandL Build Contact Page
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/349f4f31e3e7de67b6db823eead836c2/03ffe/landl-contact.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "87.2%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAARABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAEDBAIG/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQIA/9oADAMBAAIQAxAAAAHTN/csNRPP1lyDv//EABwQAAMAAQUAAAAAAAAAAAAAAAECAxIEEBMhMv/aAAgBAQABBQKnHmiRYDTxIaLF0liB0Ketv//EABYRAAMAAAAAAAAAAAAAAAAAAAEgYf/aAAgBAwEBPwEVP//EABkRAAEFAAAAAAAAAAAAAAAAABEAAQIQIf/aAAgBAgEBPwGRGJr/AP/EACAQAAECBQUAAAAAAAAAAAAAAAEAAhAREhMhM1FhgZH/2gAIAQEABj8Cpsg08qdkLTCJD253asuHkOo//8QAGhABAQEAAwEAAAAAAAAAAAAAAREAECExcf/aAAgBAQABPyFlLwhctwdyXWGCHBWC54oVtIx6LvD45//aAAwDAQACAAMAAAAQO/i8/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERECH/2gAIAQMBAT8Qmhy8Hn//xAAZEQEAAgMAAAAAAAAAAAAAAAABABEQMWH/2gAIAQIBAT8QfbE0XuGP/8QAHBABAAIDAQEBAAAAAAAAAAAAAQARITFBUWHw/9oACAEBAAE/EAV2UDg6p9iFGOhOoOr3zMyaLAjQbX5ESmoRz4DiAVTXZ+D5HcZ//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "LandL Build Contact Page",
            "title": "LandL Build Contact Page",
            "src": "/static/349f4f31e3e7de67b6db823eead836c2/953fe/landl-contact.jpg",
            "srcSet": ["/static/349f4f31e3e7de67b6db823eead836c2/20b44/landl-contact.jpg 125w", "/static/349f4f31e3e7de67b6db823eead836c2/bce2d/landl-contact.jpg 250w", "/static/349f4f31e3e7de67b6db823eead836c2/953fe/landl-contact.jpg 500w", "/static/349f4f31e3e7de67b6db823eead836c2/d0f75/landl-contact.jpg 750w", "/static/349f4f31e3e7de67b6db823eead836c2/0a251/landl-contact.jpg 1000w", "/static/349f4f31e3e7de67b6db823eead836c2/03ffe/landl-contact.jpg 1200w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`LandL Build Portfolio Item Page
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b7b4ee00c7c88b51192930333475e9f5/ae0c8/landl-work.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "138.39999999999998%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMCBAUG/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAbakJuNgkTS6qlj0BiCf/8QAHBAAAgIDAQEAAAAAAAAAAAAAAQIDEwAEMiEz/9oACAEBAAEFApaFIoOJBC67LLcre6vwn7OJxJMS1pwbMgH/xAAXEQEBAQEAAAAAAAAAAAAAAAAAEQEC/9oACAEDAQE/AZ0xVf/EABgRAQEAAwAAAAAAAAAAAAAAAAARAQIx/9oACAECAQE/Abqz1Ef/xAAiEAACAgAEBwAAAAAAAAAAAAAAAQIREyEiMTIzQVFxgZH/2gAIAQEABj8CqUE6OSXhommkaReWbdO5UUIzUX6OGPwpUf/EAB4QAAICAgIDAAAAAAAAAAAAAAABESExQVGBYXHw/9oACAEBAAE/Ibmi0pLtV2IkYSmBRfQiyKOZPocire/GGzZiW3Nix6xUxmxmWQJEFLFH/9oADAMBAAIAAwAAABC/xgH/xAAYEQEAAwEAAAAAAAAAAAAAAAABABARMf/aAAgBAwEBPxBwUZwbMU//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAECAQE/EAEEumW7V//EAB0QAQEAAgMAAwAAAAAAAAAAAAERACExQVGRsfH/2gAIAQEAAT8QSS8OTJYFzeEEd8vhwiCVJt4c3uoqVOuLG5lBk8mlxmg/Rkqk2UL+11gZsOJtnXmEE89j17i4uGN+d4zAyrIt57yCBQdTP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "LandL Build Portfolio Item Page",
            "title": "LandL Build Portfolio Item Page",
            "src": "/static/b7b4ee00c7c88b51192930333475e9f5/953fe/landl-work.jpg",
            "srcSet": ["/static/b7b4ee00c7c88b51192930333475e9f5/20b44/landl-work.jpg 125w", "/static/b7b4ee00c7c88b51192930333475e9f5/bce2d/landl-work.jpg 250w", "/static/b7b4ee00c7c88b51192930333475e9f5/953fe/landl-work.jpg 500w", "/static/b7b4ee00c7c88b51192930333475e9f5/d0f75/landl-work.jpg 750w", "/static/b7b4ee00c7c88b51192930333475e9f5/0a251/landl-work.jpg 1000w", "/static/b7b4ee00c7c88b51192930333475e9f5/ae0c8/landl-work.jpg 1420w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      